import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import BottomCTA from './BottomCTA';
import Footer from './Footer';
import { Link } from 'react-router-dom';

function AllCaseStudies() {

  const longText = "The Learning Hub, an O-levels and A-levels focused school catering to 2000 Students of grade play-group to A-levels since the past 4 decades. In response to the need for improved extracurricular management and extracurricular reporting, the school undertook the implementation of 2 key EdTech solutions by Start Up Early - Extracurricular Management System (EMS) and Extracurricular Report.";

  const inlineText = longText.split(' ').slice(0, 20).join(' ');


  return (
    <>
      <Navbar />




      <>
        {/* Card Blog */}
        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
          {/* Title */}
          <div className="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
            <h2 className="text-2xl font-bold md:text-4xl md:leading-tight darki:text-white">
              Case Studies
            </h2>
            <p className="mt-1 text-gray-600 darki:text-gray-400">
              Learn why schools love Start Up Early
            </p>
          </div>
          {/* End Title */}
          {/* Grid */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Card */}
            <Link
              className="group flex flex-col h-full border border-gray-200 hover:border-transparent hover:shadow-lg transition-all duration-300 rounded-xl p-5 darki:border-gray-700 darki:hover:border-transparent darki:hover:shadow-black/[.4] darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
              to={`/case_study/learning-hub`}
            >
              <div className="aspect-w-16 aspect-h-11">
                <img
                  className="w-[7rem] h-auto "
                  src="https://cambridge.tlh.edu.pk/wp-content/uploads/2021/12/tlh-logo-1.png"
                  alt="Image Description"
                />
              </div>
              <div className="my-6">
                <h3 className="text-xl font-semibold text-gray-800 darki:text-gray-300 darki:group-hover:text-white">
                  The Learning Hub Cambridge

                </h3>
                <p className="mt-5 text-gray-600 darki:text-gray-400">

                  The Learning Hub, an O-levels and A-levels focused school catering to 2000 Students of grade play-group to A-levels since the past 4 decades.
                </p>
              </div>

              <div className='flex justify-start'>
                <button className='btn btn-outline border-primary '>Read Story</button>
              </div>
            </Link>



            {/* 
            <div
              className="group flex flex-col h-full border border-gray-200 hover:border-transparent hover:shadow-lg transition-all duration-300 rounded-xl p-5 darki:border-gray-700 darki:hover:border-transparent darki:hover:shadow-black/[.4] darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
            >
              <div className="aspect-w-16 aspect-h-11">
                <img
                  className="w-[7rem] h-auto "
                  src="https://cambridge.tlh.edu.pk/wp-content/uploads/2021/12/tlh-logo-1.png"
                  alt="Image Description"
                />
              </div>
              <div className="my-6">
                <h3 className="text-xl font-semibold text-gray-800 darki:text-gray-300 darki:group-hover:text-white">
                  The Learning Hub Cambridge

                </h3>
                <p className="mt-5 text-gray-600 darki:text-gray-400">

                  The Learning Hub, an O-levels and A-levels focused school catering to 2000 Students of grade play-group to A-levels since the past 4 decades.
                </p>

                <span className='badge badge-primary'> Coming Soon </span>
              </div>
            </div> */}

          </div>


        </div>
        {/* End Card Blog */}
      </>



      <BottomCTA />
      <Footer />

    </>
  )
}

export default AllCaseStudies;