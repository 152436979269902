import React, { useState, useEffect } from 'react';
import { BulletList } from 'react-content-loader';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Scrollspy from 'react-scrollspy';

function Policy() {

  const navigate = useNavigate();



  const handleGoBack = (e) => {
    e.preventDefault(); // Prevent default behavior of anchor scrolling
    navigate(-1); // Navigate to the previous route
  };




  return (
    <div className='bg-[#d1a2fe17]'>

      <>
        {/* Blog Article */}
        <div className="w-full px-4 pt-6 lg:pt-10 pb-12 sm:px-6 lg:px-8 mx-auto">
          <div className="">



            <div className='flex flex-row gap-4'>



              <div className='w-9/12 sticky top-[2rem] border border-gray-200 rounded-xl bg-white z-10 p-4 h-max'>
                <Link to={'/'} className='btn btn-ghost mb-3'
                // onClick={handleGoBack} 
                >
                  {`< Go Back`}
                </Link>
                <Scrollspy
                  items={[
                    'introduction',
                    'information-we-collect',
                    'how-we-use-your-information',
                    'sharing-your-information',
                    'data-retention',
                    'security-measures',
                    'user-rights',
                    'cookies-and-tracking',
                    'children-privacy',
                    'updates-policy',
                    'transparency',
                  ]}
                  currentClassName="text-blue-600 font-bold"
                  offset={-100} // Offset to prevent overlapping header sections
                  componentTag="div"
                >

                  <a href="#introduction" className="block py-1">1. Introduction</a>
                  <a href="#information-we-collect" className="block py-1">2. Information We Collect</a>
                  <a href="#how-we-use-your-information" className="block py-1">3. How We Use Your Information</a>
                  <a href="#sharing-your-information" className="block py-1">4. Sharing Your Information</a>
                  <a href="#data-retention" className="block py-1">5. Data Retention</a>
                  <a href="#security-measures" className="block py-1">6. Security Measures</a>
                  <a href="#user-rights" className="block py-1">7. User Rights</a>
                  <a href="#cookies-and-tracking" className="block py-1">8. Cookies and Tracking</a>
                  <a href="#children-privacy" className="block py-1">9. Children’s Privacy</a>
                  <a href="#updates-policy" className="block py-1">10. Updates to This Privacy Policy</a>
                  <a href="#transparency" className="block py-1">11. Commitment to Transparency</a>
                </Scrollspy>
              </div>




              <div className="space-y-5 md:space-y-8 border border-gray-200 bg-white p-10 rounded-xl">
                <div className="space-y-3">
                  <h2 className="text-2xl font-bold md:text-3xl darki:text-white" id="introduction">
                    Privacy Policy
                  </h2>
                  <p className="text-lg text-gray-800 darki:text-neutral-200">
                    <b>Effective Date: </b> 01 December 2024
                  </p>
                </div>
                <p className="text-lg text-gray-800 darki:text-neutral-200">
                  Start Up Early ("we," "our," or "us") respects your privacy and is committed to protecting your personal information. This Privacy Policy describes how we collect, use, disclose, and safeguard your information when you use our Extracurricular Management System (EMS) or other services. It also outlines your rights and the security measures we employ to protect your data.

                </p>


                <p className="text-lg text-gray-800 darki:text-neutral-200">
                  <span className='text-xl font-semibold'>1. Introduction</span><br />

                  <p className='mt-1 pl-5'>
                    We are committed to maintaining the privacy of all users, including students, educators, parents, and institutions. This Privacy Policy explains how we comply with global privacy regulations and protect your data through secure practices.
                    <br /><br />By using our services, you consent to the collection, use, and sharing of your information as described in this Privacy Policy.
                  </p>


                </p>



                {/* 2nd section */}

                <div className="text-lg text-gray-800 darki:text-neutral-200" id='information-we-collect'>
                  <span className='text-xl font-semibold'>2. Information We Collect</span><br />

                  <div className='mt-1 pl-5'>
                    We collect the following types of information:

                    <div className="text-base text-gray-800 darki:text-neutral-200 mt-2">
                      <span className='text-base font-semibold mt-2'>2.1 Personal Information</span><br />

                      <div className='mt-1 pl-5'>
                        <ul class="marker:text-blue-600 list-disc ps-5 space-y-">
                          <li>Students: Names, student IDs, grade levels, and extracurricular activity participation details.
                            Parents and Educators: Names, contact information, and institutional affiliations.
                          </li>
                          <li>Parents and Educators: Names, contact information, and institutional affiliations.

                          </li>
                        </ul>
                      </div>


                    </div>



                    <div className="text-base text-gray-800 darki:text-neutral-200 mt-2">
                      <span className='text-base font-semibold mt-2'>2.2 Activity Data</span><br />
                      Details of extracurricular activities, including participation, performance, and skill assessments.
                    </div>

                    <div className="text-base text-gray-800 darki:text-neutral-200 mt-2">
                      <span className='text-base font-semibold mt-2'>2.3 Technical Data</span><br />
                      IP addresses, device types, operating systems, browser types, and session data.
                    </div>

                    <div className="text-base text-gray-800 darki:text-neutral-200 mt-2">
                      <span className='text-base font-semibold mt-2'>2.4 Sensitive Data                    </span><br />
                      Skill and competency evaluations related to students' educational progress.
                    </div>

                  </div>


                </div>

                {/* 2nd section ends */}




                {/* 3rd section */}

                <div className="text-lg text-gray-800 darki:text-neutral-200" id='how-we-use-your-information'>
                  <span className='text-xl font-semibold'>3. How We Use Your Information                </span><br />

                  <div className='mt-1 pl-5'>
                    We process your information for the following purposes:

                    <div className="text-base text-gray-800 darki:text-neutral-200 mt-2">
                      <span className='text-base font-semibold mt-2'>3.1 Educational Services:                    </span><br />

                      <div className='mt-1 pl-5'>
                        To assess, report, and enhance student performance and skills through our EMS platform.
                      </div>


                    </div>



                    <div className="text-base text-gray-800 darki:text-neutral-200 mt-2">
                      <span className='text-base font-semibold mt-2'>3.2 Analytics and Research:                    </span><br />
                      To analyze trends, improve system performance, and develop better educational tools.
                    </div>

                    <div className="text-base text-gray-800 darki:text-neutral-200 mt-2">
                      <span className='text-base font-semibold mt-2'>3.3 Compliance:                    </span><br />
                      To meet regulatory requirements and assist institutions in fulfilling their reporting obligations.
                    </div>

                    <div className="text-base text-gray-800 darki:text-neutral-200 mt-2">
                      <span className='text-base font-semibold mt-2'>3.4 Support and Communication:                    </span><br />
                      To address user inquiries, provide technical support, and share updates on our services.
                    </div>

                  </div>


                </div>

                {/* 3rd section ends */}



                {/* 4th section */}

                <div className="text-lg text-gray-800 darki:text-neutral-200" id='sharing-your-information'>
                  <span className='text-xl font-semibold'>4. Sharing Your Information                </span><br />

                  <div className='mt-1 pl-5'>
                    We do not sell your data. However, your data may be shared under these circumstances:

                    <div className="text-base text-gray-800 darki:text-neutral-200 mt-2">
                      <span className='text-base font-semibold mt-2'>4.1 With Educational Institutions:                    </span><br />

                      <div className='mt-1 pl-5'>
                        To provide reports and insights on student development for authorized schools and stakeholders.
                      </div>


                    </div>



                    <div className="text-base text-gray-800 darki:text-neutral-200 mt-2">
                      <span className='text-base font-semibold mt-2'>4.2 With Service Providers:                    </span><br />
                      For system hosting, analytics, and customer support, under strict confidentiality agreements.
                    </div>

                    <div className="text-base text-gray-800 darki:text-neutral-200 mt-2">
                      <span className='text-base font-semibold mt-2'>4.3 For Legal Compliance:                    </span><br />
                      To comply with applicable laws, regulations, or court orders.
                    </div>
                  </div>


                </div>

                {/* 4th section ends */}


                {/* 5th section */}


                <div className="text-lg text-gray-800 darki:text-neutral-200" id='data-retention'>
                  <span className='text-xl font-semibold'>5. Data Retention</span><br />

                  <div className='mt-1 pl-5'>
                    We retain personal information only as long as necessary for:
                    <ul class="marker:text-blue-600 list-disc ps-5 space-y-">
                      <li>Delivering our services.</li>
                      <li>Meeting legal and contractual obligations.</li>
                      <li>Addressing disputes or enforcing agreements.
                      </li>
                      <li>All retained data is stored securely and regularly reviewed for relevance.
                      </li>

                    </ul>

                  </div>


                </div>

                {/* 5th section ends here */}



                {/* 6th section */}


                <div className="text-lg text-gray-800 darki:text-neutral-200" id='security measures'>
                  <span className='text-xl font-semibold'>6. Security Measures                </span><br />

                  <div className='mt-1 pl-5'>
                    To ensure the safety of your data, we implement industry-leading security measures, including:

                    <div className="text-base text-gray-800 darki:text-neutral-200 mt-2">
                      <span className='text-base font-semibold mt-2'>6.1 Data Encryption:                    </span><br />

                      <div className='mt-1 pl-5'>
                        <ul class="marker:text-blue-600 list-disc ps-5 space-y-">
                          <li>All stored data is encrypted using AES-256 standards.</li>
                          <li> Data in transit is secured using HTTPS with TLS 3.0.</li>

                        </ul>
                      </div>


                    </div>



                    <div className="text-base text-gray-800 darki:text-neutral-200 mt-2">
                      <span className='text-base font-semibold mt-2'>6.2 Authentication and Access Control:                    </span><br />

                      <div className='mt-1 pl-5'>
                        <ul class="marker:text-blue-600 list-disc ps-5 space-y-">
                          <li>Strong password policies, salted and hashed password storage, and multi-factor authentication (MFA).
                          </li>
                          <li>Role-based access control (RBAC) to limit access based on user roles.
                          </li>

                        </ul>
                      </div>


                    </div>



                    <div className="text-base text-gray-800 darki:text-neutral-200 mt-2">
                      <span className='text-base font-semibold mt-2'>6.3 Threat Prevention:                    </span><br />

                      <div className='mt-1 pl-5'>
                        <ul class="marker:text-blue-600 list-disc ps-5 space-y-">
                          <li>Input validation and sanitization to prevent SQL injection and cross-site scripting (XSS).</li>

                          <li>SSL certificate pinning to protect against man-in-the-middle (MitM) attacks.</li>
                        </ul>
                      </div>


                    </div>




                    <div className="text-base text-gray-800 darki:text-neutral-200 mt-2">
                      <span className='text-base font-semibold mt-2'>6.4 Backup and Recovery:                    </span><br />

                      <div className='mt-1 pl-5'>
                        <ul class="marker:text-blue-600 list-disc ps-5 space-y-">
                          <li>Hourly backups of data and daily system image backups.
                          </li>

                          <li>Disaster recovery plans for prompt restoration of services.
                          </li>
                        </ul>
                      </div>


                    </div>




                    <div className="text-base text-gray-800 darki:text-neutral-200 mt-2">
                      <span className='text-base font-semibold mt-2'>6.5 API Security:                    </span><br />

                      <div className='mt-1 pl-5'>
                        <ul class="marker:text-blue-600 list-disc ps-5 space-y-">
                          <li>Secure APIs with authentication, authorization, and rate limiting to prevent brute-force attacks.
                          </li>
                        </ul>
                      </div>


                    </div>


                  </div>


                </div>

                {/* 6th section ends */}


                {/* 7th section */}

                <div className="text-lg text-gray-800 darki:text-neutral-200" id='user-rights'>
                  <span className='text-xl font-semibold'>7. User Rights                </span><br />

                  <div className='mt-1 pl-5'>
                    We retain personal information only as long as necessary for:
                    <ul class="marker:text-blue-600 list-disc ps-5 space-y-">
                      <li>You have the following rights regarding your data:                    </li>
                      <li>
                        Request access to the personal data we hold about you.

                      </li>
                      <li>
                        Request correction of inaccurate or incomplete data.
                      </li>

                      <li>
                        Request deletion of your data, subject to legal or contractual requirements.
                      </li>

                      <li>
                        Request transfer of your data to another organization.
                      </li>

                      <li>
                        Withdraw consent for data processing where applicable.
                      </li>

                      <li>
                        To exercise these rights, contact us at <a className='link link-primary' href='mailto:hello@startupearly.com.'>hello@startupearly.com.</a>
                      </li>


                    </ul>

                  </div>


                </div>

                {/* 7th section ends */}


                {/* 8th section */}

                <div className="text-lg text-gray-800 darki:text-neutral-200" id='cookies-and-tracking'>
                  <span className='text-xl font-semibold'>8. Cookies and Tracking                </span><br />

                  <div className='mt-1 pl-5'>
                    We use cookies and similar technologies to:
                    <ul class="marker:text-blue-600 list-disc ps-5 space-y-">

                      <li>
                        Enhance user experience and platform functionality.
                      </li>

                      <li>
                        Track usage patterns for analytics and service improvements.
                      </li>

                      <li>
                        You can manage your cookie preferences via browser settings.
                      </li>


                    </ul>

                  </div>


                </div>

                {/* 8th section ends */}




                {/* 9th section */}

                <div className="text-lg text-gray-800 darki:text-neutral-200" id='children-privacy'>
                  <span className='text-xl font-semibold'>9. Children’s Privacy                </span><br />

                  <div className='mt-1 pl-5'>
                    We are committed to protecting children’s data privacy:
                    <ul class="marker:text-blue-600 list-disc ps-5 space-y-">

                      <li>
                        Data is collected only with the consent of parents, guardians, or educational institutions.
                      </li>

                      <li>
                        Information is used strictly for educational purposes.
                      </li>


                    </ul>

                  </div>


                </div>

                {/* 9th section ends */}

                <div className="text-lg text-gray-800 darki:text-neutral-200">
                  <span className='text-xl font-semibold'>9. Children’s Privacy                </span><br />

                  <div className='mt-1 pl-5'>
                    We are committed to protecting children’s data privacy:
                    <ul class="marker:text-blue-600 list-disc ps-5 space-y-">

                      <li>
                        Data is collected only with the consent of parents, guardians, or educational institutions.
                      </li>

                      <li>
                        Information is used strictly for educational purposes.
                      </li>


                    </ul>

                  </div>


                </div>
                {/* 10th section */}

                <div className="text-lg text-gray-800 darki:text-neutral-200" id='updates-policy'>
                  <span className='text-xl font-semibold'>10. Updates to This Privacy Policy
                  </span><br />

                  <div className='mt-1 pl-5'>
                    We may update this Privacy Policy to reflect changes in our operations, legal obligations, or regulatory requirements. The "Effective Date" at the top of this policy indicates when it was last revised. Material changes will be communicated via email or platform notifications.

                  </div>


                </div>

                {/* 10th section ends */}


                {/* 11th section */}

                <div className="text-lg text-gray-800 darki:text-neutral-200" id='transparency'>
                  <span className='text-xl font-semibold'>11. Commitment to Transparency
                  </span><br />

                  <div className='mt-1 pl-5'>
                    We believe in informing you about how we use and protect your data. For any questions or concerns, reach out to us at   <a className='link link-primary' href='mailto:hello@startupearly.com.'>hello@startupearly.com.</a>. Startup Early is dedicated to creating a secure and empowering environment for education. Your trust is invaluable, and we're committed to maintaining the highest data protection standards.


                  </div>


                </div>

                {/* 11th section ends */}



              </div>

            </div>



            {/* Content */}

            {/* End Content */}
          </div>
        </div>



        {/* End Sticky Share Group */}
      </>



    </div>
  )
}

export default Policy;