import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook } from "react-icons/fa";
import { ImMail4 } from "react-icons/im";
import { AiFillTwitterCircle } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { ImLinkedin } from "react-icons/im";

function Footer() {

  const currentYear = new Date().getFullYear();
  const sections = [
    {
      title: "Company",
      links: [
        { name: "About Us", link: "/about_us", isDisabled: true },
        { name: "Get Demo", link: "/book_demo", isDisabled: false },
        { name: "Login", link: "/login", isDisabled: false },
      ],
    },
    {
      title: "Key Features",
      links: [
        { name: "Extracurricular Management System", link: "/features/ems", isDisabled: false },
        { name: "Extracurricular Reports", link: "/features/cct", isDisabled: false },
        { name: "WEB / Mobile App", link: "/features/leap", isDisabled: false },
      ],
    },
    {
      title: "Resources",
      links: [
        { name: "Case Studies", link: "/case_studies", isDisabled: false },
        { name: "Blogs", link: "/blogs", isDisabled: false },
        { name: "Knowledge Base", link: "", isDisabled: true },
      ],
    },
    {
      title: "Legal",
      links: [
        { name: "Privacy Policy", link: "/policy", isDisabled: false },
      ],
    },
  ];


  return (
    <footer className="bg-primary w-full px-4">
      <div className="w-full max-w-[75rem] py-10 px-0 sm:px-0 lg:px-0 lg:pt-20 mx-auto">
        {/* Grid */}
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-6">
          <div className="col-span-full lg:col-span-1">
            <Link to="/">
              <img
                className="w-[6rem] h-auto"
                src="https://res.cloudinary.com/ahbranding/image/upload/v1708415696/AHbranding/logo_white_r1bqtu.png"
                alt="Logo"
              />
            </Link>
          </div>
          {/* Dynamically render sections */}
          {sections.map((section, index) => (
            <div key={index} className="col-span-1">
              <h4 className="font-semibold text-xl text-gray-100">{section.title}</h4>
              <div className="mt-3 grid space-y-3">
                {section.links.map((link, idx) => (
                  <p key={idx} className="m-0">
                    {link.isDisabled ? (
                      <div className="tooltip" data-tip="Coming Soon">
                        <button className="inline-flex gap-x-2 text-white opacity-80 hover:text-gray-200 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600">{link.name}</button>
                      </div>
                    ) : (
                      <Link
                        to={link.link}
                        className={`inline-flex gap-x-2 text-white opacity-80 hover:text-gray-200 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600 ${link.isDisabled ? "pointer-events-none opacity-50" : ""
                          }`}
                      >
                        {link.name}
                      </Link>
                    )}


                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>
        {/* End Grid */}
        <div className="mt-5 sm:mt-12 grid gap-y-2 sm:gap-y-0 sm:flex sm:justify-between sm:items-center">
          <div className="flex justify-between items-center">
            <p className="text-sm text-white opacity-80">
              © {currentYear} Start Up Early. All rights reserved.
            </p>
          </div>
          {/* Social Media Icons */}
          <div className="flex flex-row items-center gap-2">
            <Link className="btn btn-circle btn-ghost" target="_blank" to="https://www.facebook.com/StartupEarly.Co/">
              <FaFacebook size={25} />
            </Link>
            <Link className="btn btn-circle btn-ghost" target="_blank" to="mailto:hello@startupearly.com">
              <ImMail4 size={25} />
            </Link>
            <Link className="btn btn-circle btn-ghost" target="_blank" to="https://twitter.com/early_startup?lang=en">
              <AiFillTwitterCircle size={28} />
            </Link>
            <Link className="btn btn-circle btn-ghost" target="_blank" to="https://www.instagram.com/startupearly/?hl=en">
              <AiFillInstagram size={29} />
            </Link>
            <Link className="btn btn-circle btn-ghost" target="_blank" to="https://pk.linkedin.com/company/startupearly">
              <ImLinkedin size={25} />
            </Link>
          </div>
          {/* End Social Media Icons */}
        </div>
      </div>
    </footer>

  )
}

export default Footer;